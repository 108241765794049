import io from 'socket.io-client';

const API_URL = process.env.NODE_ENV === 'production' 
  ? 'https://wish.owlsom.com/api'
  : 'http://localhost:5000/api';

const SOCKET_URL = process.env.NODE_ENV === 'production'
  ? 'https://wish.owlsom.com'
  : 'http://localhost:5000';

const socket = io(SOCKET_URL);

export const wishesApi = {
  connectToRoom(accessKey: string) {
    socket.emit('joinRoom', accessKey);
    return socket;
  },

  async getWishes(accessKey: string) {
    const response = await fetch(`${API_URL}/wishes/${accessKey}`);
    if (!response.ok) throw new Error('Failed to fetch wishes');
    return response.json();
  },

  async submitWish(formData: FormData) {
    const response = await fetch(`${API_URL}/wishes`, {
      method: 'POST',
      body: formData
    });
    if (!response.ok) throw new Error('Failed to submit wish');
    return response.json();
  },

  async updateWishApproval(id: number, approved: boolean) {
    const response = await fetch(`${API_URL}/wishes/${id}/approve`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ approved })
    });
    if (!response.ok) throw new Error('Failed to update wish');
    return response.json();
  }
};