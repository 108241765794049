import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Copy, Eye, EyeOff } from 'lucide-react';
import { getSetting, updateSetting } from '../../lib/db';
import type { ClientSession } from '../../lib/types/admin';

interface SessionInfoProps {
  session: ClientSession;
}

export default function SessionInfo({ session }: SessionInfoProps) {
  const [copied, setCopied] = useState<string | null>(null);
  const [showQRCodes, setShowQRCodes] = useState(true);
  const [sessionTimeout, setSessionTimeout] = useState(30);

  useEffect(() => {
    const loadSettings = async () => {
      const [showQR, timeout] = await Promise.all([
        getSetting('showQRCodes'),
        getSetting('sessionTimeout')
      ]);
      setShowQRCodes(showQR !== false); // Default to true if not set
      setSessionTimeout(timeout || 30);
    };
    loadSettings();
  }, []);

  const handleCopy = async (text: string, type: string) => {
    await navigator.clipboard.writeText(text);
    setCopied(type);
    setTimeout(() => setCopied(null), 2000);
  };

  const toggleQRCodes = async () => {
    const newValue = !showQRCodes;
    await updateSetting('showQRCodes', newValue);
    setShowQRCodes(newValue);
  };

  const baseUrl = window.location.origin;
  const displayUrl = `${baseUrl}${session.displayUrl}`;
  const formUrl = `${baseUrl}${session.formUrl}`;

  return (
    <div className="bg-white rounded-lg shadow-md p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-800">Your Unique URLs</h2>
        <button
          onClick={toggleQRCodes}
          className="p-2 text-gray-600 hover:text-gray-900 rounded-lg hover:bg-gray-100"
          title={showQRCodes ? 'Hide QR Codes' : 'Show QR Codes'}
        >
          {showQRCodes ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
        </button>
      </div>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Display Screen URL
          </label>
          <div className="flex items-center gap-2">
            <input
              type="text"
              value={displayUrl}
              readOnly
              className="flex-1 px-4 py-2 bg-gray-50 border border-gray-300 rounded-lg"
            />
            <button
              onClick={() => handleCopy(displayUrl, 'display')}
              className="p-2 text-gray-600 hover:text-gray-900"
              title="Copy display URL"
            >
              <Copy className="w-5 h-5" />
            </button>
          </div>
          {copied === 'display' && (
            <p className="text-sm text-green-600 mt-1">Copied to clipboard!</p>
          )}
          {showQRCodes && (
            <div className="mt-2">
              <QRCodeSVG value={displayUrl} size={128} />
            </div>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Wish Form URL
          </label>
          <div className="flex items-center gap-2">
            <input
              type="text"
              value={formUrl}
              readOnly
              className="flex-1 px-4 py-2 bg-gray-50 border border-gray-300 rounded-lg"
            />
            <button
              onClick={() => handleCopy(formUrl, 'form')}
              className="p-2 text-gray-600 hover:text-gray-900"
              title="Copy form URL"
            >
              <Copy className="w-5 h-5" />
            </button>
          </div>
          {copied === 'form' && (
            <p className="text-sm text-green-600 mt-1">Copied to clipboard!</p>
          )}
          {showQRCodes && (
            <div className="mt-2">
              <QRCodeSVG value={formUrl} size={128} />
            </div>
          )}
        </div>
      </div>

      <p className="text-sm text-gray-500 mt-4">
        Session will expire after {sessionTimeout} minutes of inactivity
      </p>
    </div>
  );
}