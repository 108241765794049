// Database names and store names
export const DB_NAMES = {
  WISHES: 'wishesDB',
  SUPER_ADMIN: 'superAdminDB',
  SESSIONS: 'sessionsDB'
} as const;

export const STORE_NAMES = {
  WISHES: 'wishes',
  SETTINGS: 'settings',
  ADMIN: 'admins',
  PLATFORM_SETTINGS: 'platformSettings',
  SESSIONS: 'sessions'
} as const;