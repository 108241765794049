import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Send, Image as ImageIcon, Loader, AlertCircle } from 'lucide-react';
import { wishesApi } from '../lib/api/wishes';

export default function WishForm() {
  const { accessKey } = useParams();
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [showPhotoReminder, setShowPhotoReminder] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!name || !message) return;
    
    if (!image) {
      setShowPhotoReminder(true);
      setError('Please upload a photo with your message');
      return;
    }

    setLoading(true);
    setError('');
    setShowPhotoReminder(false);
    
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('message', message);
      formData.append('photo', image);
      if (accessKey) formData.append('accessKey', accessKey);

      await wishesApi.submitWish(formData);

      setName('');
      setMessage('');
      setImage(null);
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
    } catch (error) {
      console.error('Error submitting wish:', error);
      setError(error instanceof Error ? error.message : 'Failed to send wish. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-2xl shadow-xl p-8">
        <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
          Send Your Wishes
        </h2>
        
        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg flex items-center gap-2">
            <AlertCircle className="w-5 h-5" />
            {error}
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Your Name
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              required
              maxLength={50}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Your Message
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent h-32"
              required
              maxLength={500}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Add Photo (required)
            </label>
            <div className={`relative ${showPhotoReminder ? 'animate-bounce' : ''}`}>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file && file.size > 5 * 1024 * 1024) {
                    setError('Image size must be less than 5MB');
                    return;
                  }
                  setImage(file || null);
                  setShowPhotoReminder(false);
                  setError('');
                }}
                className="hidden"
                id="image-upload"
                required
              />
              <label
                htmlFor="image-upload"
                className={`flex items-center justify-center px-4 py-2 border rounded-lg cursor-pointer hover:bg-gray-50 transition-colors
                  ${showPhotoReminder ? 'border-red-500 bg-red-50' : 'border-gray-300'}`}
              >
                <ImageIcon className={`w-5 h-5 mr-2 ${showPhotoReminder ? 'text-red-500' : ''}`} />
                {image ? image.name : 'Choose an image'}
              </label>
            </div>
          </div>

          <button
            type="submit"
            disabled={loading || success}
            className={`w-full py-3 px-4 rounded-lg text-white font-medium flex items-center justify-center
              ${loading || success 
                ? 'bg-green-500' 
                : 'bg-purple-600 hover:bg-purple-700'}`}
          >
            {loading ? (
              <Loader className="w-5 h-5 animate-spin" />
            ) : success ? (
              'Wish Sent! ✨'
            ) : (
              <>
                <Send className="w-5 h-5 mr-2" />
                Send Wish
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
}