import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateClientSession } from '../lib/auth/clientAuth';
import { useSession } from '../lib/hooks/useSession';
import SessionInfo from './shared/SessionInfo';
import WishesManagement from './dashboard/WishesManagement';
import SettingsPanel from './dashboard/SettingsPanel';
import { Settings } from 'lucide-react';

export default function ClientDashboard() {
  const navigate = useNavigate();
  const username = sessionStorage.getItem('clientUsername');
  const { session, loading, error } = useSession(username);

  useEffect(() => {
    const checkSession = async () => {
      if (!username) {
        navigate('/client');
        return;
      }

      const isValid = await validateClientSession();
      if (!isValid) {
        navigate('/client');
      }
    };

    checkSession();
    const interval = setInterval(checkSession, 60000); // Check session every minute
    return () => clearInterval(interval);
  }, [navigate, username]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-xl text-gray-600">Loading...</div>
      </div>
    );
  }

  if (error || !session) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-xl text-red-600">
          {error || 'Session not found. Please login again.'}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-2xl font-bold text-gray-800">Client Dashboard</h1>
            <button
              onClick={() => navigate('/client')}
              className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
            >
              Logout
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <SessionInfo session={session} />
            
            <div className="space-y-6">
              <div>
                <h2 className="text-xl font-semibold flex items-center mb-4">
                  <Settings className="w-5 h-5 mr-2" />
                  Display Settings
                </h2>
                <SettingsPanel />
              </div>
            </div>
          </div>

          <div className="mt-8">
            <WishesManagement />
          </div>
        </div>
      </div>
    </div>
  );
}