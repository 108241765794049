import { openDB } from 'idb';
import { DB_NAMES, STORE_NAMES } from '../constants/database';
import { getSetting } from '../db';

interface ClientSession {
  username: string;
  accessKey: string;
  lastActivity: number;
  displayUrl: string;
  formUrl: string;
}

class SessionManager {
  private db: Promise<IDBDatabase>;
  private static DEFAULT_TIMEOUT = 30 * 60 * 1000; // 30 minutes

  constructor() {
    this.db = openDB(DB_NAMES.SESSIONS, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAMES.SESSIONS)) {
          const store = db.createObjectStore(STORE_NAMES.SESSIONS, { keyPath: 'username' });
          store.createIndex('accessKey', 'accessKey', { unique: true });
          store.createIndex('lastActivity', 'lastActivity');
        }
      }
    });
  }

  private async getSessionTimeout(): Promise<number> {
    const timeout = await getSetting('sessionTimeout');
    return (timeout || 30) * 60 * 1000; // Convert minutes to milliseconds
  }

  async createSession(username: string, accessKey: string): Promise<ClientSession> {
    const db = await this.db;
    
    // Check for existing session
    const existingSession = await db.get(STORE_NAMES.SESSIONS, username);
    if (existingSession) {
      throw new Error('Session already exists for this user');
    }

    const session: ClientSession = {
      username,
      accessKey,
      lastActivity: Date.now(),
      displayUrl: `/display/${accessKey}`,
      formUrl: `/form/${accessKey}`
    };

    await db.put(STORE_NAMES.SESSIONS, session);
    return session;
  }

  async validateSession(username: string, accessKey: string): Promise<boolean> {
    const db = await this.db;
    const session = await db.get(STORE_NAMES.SESSIONS, username);
    
    if (!session || session.accessKey !== accessKey) {
      return false;
    }

    const timeout = await this.getSessionTimeout();
    const isExpired = Date.now() - session.lastActivity > timeout;
    if (isExpired) {
      await this.endSession(username);
      return false;
    }

    // Update last activity
    session.lastActivity = Date.now();
    await db.put(STORE_NAMES.SESSIONS, session);
    return true;
  }

  async getSession(username: string): Promise<ClientSession | null> {
    const db = await this.db;
    return db.get(STORE_NAMES.SESSIONS, username);
  }

  async endSession(username: string): Promise<void> {
    const db = await this.db;
    await db.delete(STORE_NAMES.SESSIONS, username);
  }

  async cleanupExpiredSessions(): Promise<void> {
    const db = await this.db;
    const tx = db.transaction(STORE_NAMES.SESSIONS, 'readwrite');
    const store = tx.store;
    const sessions = await store.getAll();
    const timeout = await this.getSessionTimeout();

    const now = Date.now();
    for (const session of sessions) {
      if (now - session.lastActivity > timeout) {
        await store.delete(session.username);
      }
    }
  }
}

export const sessionManager = new SessionManager();