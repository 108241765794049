import { v4 as uuidv4 } from 'uuid';
import bcrypt from 'bcryptjs-react';
import { openDB } from 'idb';
import { DB_NAMES, STORE_NAMES } from './constants/database';
import type { AdminConfig, PlatformSettings } from './types/admin';

class SuperAdminManager {
  private db: Promise<IDBDatabase>;

  constructor() {
    this.db = openDB(DB_NAMES.SUPER_ADMIN, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAMES.ADMIN)) {
          const adminStore = db.createObjectStore(STORE_NAMES.ADMIN, { keyPath: 'id' });
          adminStore.createIndex('username', 'username', { unique: true });
          adminStore.createIndex('accessKey', 'accessKey', { unique: true });
        }

        if (!db.objectStoreNames.contains(STORE_NAMES.PLATFORM_SETTINGS)) {
          db.createObjectStore(STORE_NAMES.PLATFORM_SETTINGS);
        }
      }
    });
  }

  async getAllAdmins(): Promise<AdminConfig[]> {
    const db = await this.db;
    return db.getAll(STORE_NAMES.ADMIN);
  }

  async getAdminByUsername(username: string): Promise<AdminConfig | undefined> {
    const db = await this.db;
    const tx = db.transaction(STORE_NAMES.ADMIN, 'readonly');
    const index = tx.store.index('username');
    return index.get(username);
  }

  async createAdmin(
    username: string, 
    password: string, 
    maxWishesLimit: number = 1000,
    customDomain?: string
  ): Promise<AdminConfig> {
    const db = await this.db;
    
    const existingAdmin = await this.getAdminByUsername(username);
    if (existingAdmin) {
      throw new Error('Username already exists');
    }

    const hashedPassword = await bcrypt.hash(password, 10);
    const accessKey = uuidv4();
    const adminConfig: AdminConfig = {
      id: uuidv4(),
      username,
      hashedPassword,
      accessKey,
      isActive: true,
      maxWishesLimit,
      customDomain,
      createdAt: Date.now()
    };

    const writeTx = db.transaction(STORE_NAMES.ADMIN, 'readwrite');
    await writeTx.store.add(adminConfig);
    await writeTx.done;

    return adminConfig;
  }

  async deleteAdmin(adminId: string): Promise<void> {
    const db = await this.db;
    const tx = db.transaction(STORE_NAMES.ADMIN, 'readwrite');
    await tx.store.delete(adminId);
    await tx.done;
  }

  async authenticateAdmin(username: string, password: string): Promise<AdminConfig | null> {
    const admin = await this.getAdminByUsername(username);
    if (!admin || !admin.isActive) return null;

    const isValidPassword = await bcrypt.compare(password, admin.hashedPassword);
    if (isValidPassword) {
      sessionStorage.setItem('adminUsername', admin.username);
      
      const db = await this.db;
      const updateTx = db.transaction(STORE_NAMES.ADMIN, 'readwrite');
      admin.lastLogin = Date.now();
      await updateTx.store.put(admin);
      await updateTx.done;
    }
    
    return isValidPassword ? admin : null;
  }

  async updateAdminStatus(adminId: string, updates: Partial<AdminConfig>): Promise<void> {
    const db = await this.db;
    const tx = db.transaction(STORE_NAMES.ADMIN, 'readwrite');
    const admin = await tx.store.get(adminId);

    if (!admin) {
      throw new Error('Admin not found');
    }

    const updatedAdmin = { ...admin, ...updates };
    await tx.store.put(updatedAdmin);
    await tx.done;
  }

  async updateAdminPassword(username: string, newPassword: string): Promise<void> {
    const admin = await this.getAdminByUsername(username);
    if (!admin) {
      throw new Error('Admin not found');
    }

    const db = await this.db;
    const tx = db.transaction(STORE_NAMES.ADMIN, 'readwrite');
    const hashedPassword = await bcrypt.hash(newPassword, 10);
    admin.hashedPassword = hashedPassword;
    await tx.store.put(admin);
    await tx.done;
  }

  async getPlatformSettings(): Promise<PlatformSettings> {
    const db = await this.db;
    const settings = await db.get(STORE_NAMES.PLATFORM_SETTINGS, 'globalSettings');
    return settings || {
      globalWishesLimit: 10000,
      maintenanceMode: false,
      allowNewAdminRegistration: true
    };
  }

  async updatePlatformSettings(settings: Partial<PlatformSettings>): Promise<void> {
    const db = await this.db;
    const currentSettings = await this.getPlatformSettings();
    const updatedSettings = { ...currentSettings, ...settings };
    await db.put(STORE_NAMES.PLATFORM_SETTINGS, updatedSettings, 'globalSettings');
  }
}

export const superAdminManager = new SuperAdminManager();