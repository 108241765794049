const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:5000/api';

export const authApi = {
  async login(username: string, password: string) {
    const response = await fetch(`${API_URL}/clients/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    });

    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.message || 'Authentication failed');
    }

    return data;
  },

  async validateSession(username: string, accessKey: string) {
    const response = await fetch(`${API_URL}/auth/validate-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, accessKey })
    });

    if (!response.ok) {
      return false;
    }

    const data = await response.json();
    return data.valid;
  },

  async logout(username: string) {
    await fetch(`${API_URL}/auth/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username })
    });
  }
};