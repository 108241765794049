import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Heart } from 'lucide-react';
import { getApprovedWishes, getSetting, type Wish, type AnimationStyle } from '../lib/db';

const animations = {
  bubble: {
    initial: { scale: 0.8, opacity: 0, y: 50, rotate: -5 },
    animate: { 
      scale: 1, 
      opacity: 1, 
      y: 0, 
      rotate: 0,
      transition: { type: "spring", stiffness: 100, damping: 15 }
    },
    exit: { 
      scale: 0.8, 
      opacity: 0, 
      y: -50, 
      rotate: 5,
      transition: { duration: 0.5 }
    }
  },
  fade: {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 1 } },
    exit: { opacity: 0, transition: { duration: 1 } }
  },
  slide: {
    initial: { x: 300, opacity: 0 },
    animate: { x: 0, opacity: 1, transition: { type: "spring", stiffness: 100 } },
    exit: { x: -300, opacity: 0, transition: { duration: 0.5 } }
  },
  carousel: {
    initial: { scale: 0.5, opacity: 0, x: 200 },
    animate: { 
      scale: 1, 
      opacity: 1, 
      x: 0,
      transition: { 
        type: "spring",
        stiffness: 50,
        damping: 20
      }
    },
    exit: { 
      scale: 0.5, 
      opacity: 0, 
      x: -200,
      transition: { duration: 0.8 }
    }
  },
  zoom: {
    initial: { scale: 1.5, opacity: 0 },
    animate: { 
      scale: 1,
      opacity: 1,
      transition: { 
        type: "spring",
        stiffness: 70,
        damping: 20
      }
    },
    exit: { 
      scale: 1.5,
      opacity: 0,
      transition: { duration: 0.7 }
    }
  }
};

interface WishCardProps {
  wish: Wish;
  animationStyle: AnimationStyle;
  photoFrameColor: string;
  photoShadow: string;
}

const WishCard: React.FC<WishCardProps> = ({ wish, animationStyle, photoFrameColor, photoShadow }) => {
  const cardContent = (
    <>
      {wish.imageUrl && (
        <motion.div 
          className="mb-6 flex justify-center"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <img
            src={wish.imageUrl}
            alt="Wish"
            className={`rounded-2xl max-h-48 object-cover ${photoFrameColor} ${photoShadow} border-2`}
          />
        </motion.div>
      )}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
      >
        <p className="text-2xl text-white mb-4 font-light italic leading-relaxed max-w-md">
          "{wish.message}"
        </p>
        <p className="text-xl text-white/90 font-semibold">
          - {wish.name}
        </p>
      </motion.div>
    </>
  );

  return (
    <motion.div
      variants={animations[animationStyle]}
      initial="initial"
      animate="animate"
      exit="exit"
      className="backdrop-blur-lg rounded-3xl p-8 relative mx-4"
      style={{
        background: 'rgba(0, 0, 0, 0.3)',
        boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
        border: '1px solid rgba(255, 255, 255, 0.18)'
      }}
    >
      {cardContent}
    </motion.div>
  );
};

export default function DisplayScreen() {
  const { accessKey } = useParams();
  const [wishes, setWishes] = useState<Wish[]>([]);
  const [currentWishIndex, setCurrentWishIndex] = useState(0);
  const [title, setTitle] = useState('Send Your Wishes Here');
  const [footerTitle, setFooterTitle] = useState('Made with ❤️');
  const [backgroundGradient, setBackgroundGradient] = useState('from-purple-600 to-blue-500');
  const [animationDuration, setAnimationDuration] = useState(8000);
  const [displayCount, setDisplayCount] = useState(1);
  const [animationStyle, setAnimationStyle] = useState<AnimationStyle>('bubble');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [backgroundVideo, setBackgroundVideo] = useState('');
  const [videoLoop, setVideoLoop] = useState(true);
  const [randomDisplay, setRandomDisplay] = useState(false);
  const [titleColor, setTitleColor] = useState('text-white');
  const [titleShadow, setTitleShadow] = useState(true);
  const [photoFrameColor, setPhotoFrameColor] = useState('border-white');
  const [photoShadow, setPhotoShadow] = useState('shadow-xl');

  useEffect(() => {
    const loadData = async () => {
      const [
        loadedWishes,
        currentTitle,
        currentFooterTitle,
        gradient,
        duration,
        count,
        style,
        bgImage,
        bgVideo,
        loop,
        random,
        tColor,
        tShadow,
        pFrameColor,
        pShadow
      ] = await Promise.all([
        getApprovedWishes(accessKey),
        getSetting('title'),
        getSetting('footerTitle'),
        getSetting('backgroundGradient'),
        getSetting('animationDuration'),
        getSetting('displayCount'),
        getSetting('animationStyle'),
        getSetting('backgroundImage'),
        getSetting('backgroundVideo'),
        getSetting('videoLoop'),
        getSetting('randomDisplay'),
        getSetting('titleColor'),
        getSetting('titleShadow'),
        getSetting('photoFrameColor'),
        getSetting('photoShadow')
      ]);
      
      const duplicatedWishes = [...loadedWishes, ...loadedWishes];
      setWishes(duplicatedWishes);
      setTitle(currentTitle);
      setFooterTitle(currentFooterTitle);
      setBackgroundGradient(gradient);
      setAnimationDuration(duration);
      setDisplayCount(count);
      setAnimationStyle(style);
      setBackgroundImage(bgImage);
      setBackgroundVideo(bgVideo);
      setVideoLoop(loop);
      setRandomDisplay(random);
      setTitleColor(tColor);
      setTitleShadow(tShadow);
      setPhotoFrameColor(pFrameColor);
      setPhotoShadow(pShadow);
    };

    loadData();
    const interval = setInterval(loadData, 2000);
    return () => clearInterval(interval);
  }, [accessKey]);

  useEffect(() => {
    if (wishes.length > 0) {
      const timer = setInterval(() => {
        if (randomDisplay) {
          const randomIndex = Math.floor(Math.random() * wishes.length);
          setCurrentWishIndex(randomIndex);
        } else {
          setCurrentWishIndex((prev) => (prev + displayCount) % wishes.length);
        }
      }, animationDuration);
      return () => clearInterval(timer);
    }
  }, [wishes.length, animationDuration, displayCount, randomDisplay]);

  const backgroundStyle = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  };

  const titleClasses = `text-4xl font-bold ${titleColor} w-full text-center mb-12 absolute top-8 left-0 right-0 ${
    titleShadow ? 'drop-shadow-lg' : ''
  }`;

  if (wishes.length === 0) {
    return (
      <div 
        className={`min-h-screen ${!backgroundImage ? `bg-gradient-to-br ${backgroundGradient}` : ''} flex items-center justify-center`}
        style={backgroundStyle}
      >
        <div className="text-white text-center">
          <h1 className={titleClasses}>{title}</h1>
          <Heart className="w-16 h-16 mx-auto mb-4 animate-pulse" />
          <h2 className="text-3xl font-semibold">Waiting for wishes...</h2>
        </div>
      </div>
    );
  }

  return (
    <div 
      className={`min-h-screen ${!backgroundImage ? `bg-gradient-to-br ${backgroundGradient}` : ''} flex flex-col items-start justify-center p-8 overflow-hidden relative`}
      style={backgroundStyle}
    >
      {backgroundVideo && (
        <video
          autoPlay
          loop={videoLoop}
          muted
          playsInline
          className="absolute inset-0 w-full h-full object-cover"
        >
          <source src={backgroundVideo} type="video/mp4" />
        </video>
      )}
      
      <h1 className={titleClasses}>{title}</h1>
      
      <div className="flex justify-center w-full mt-16 gap-8">
        <AnimatePresence mode="wait">
          {wishes.slice(currentWishIndex, currentWishIndex + displayCount).map((wish, index) => (
            <WishCard
              key={`${wish.id}-${index}`}
              wish={wish}
              animationStyle={animationStyle}
              photoFrameColor={photoFrameColor}
              photoShadow={photoShadow}
            />
          ))}
        </AnimatePresence>
      </div>

      <div className="absolute bottom-4 left-0 right-0 text-center text-white text-lg font-medium">
        {footerTitle}
      </div>
    </div>
  );
}