import React from 'react';
import { Lock, Unlock, Trash2, Key } from 'lucide-react';
import type { AdminConfig } from '../../lib/types/admin';

interface ClientListProps {
  clients: AdminConfig[];
  onStatusChange: (adminId: string, isActive: boolean) => void;
  onDelete: (adminId: string) => void;
  onPasswordChange: (adminId: string) => void;
  passwordChangeForm: {
    adminId: string;
    newPassword: string;
  };
  onPasswordFormChange: (adminId: string, newPassword: string) => void;
  onPasswordFormCancel: () => void;
}

export function ClientList({
  clients,
  onStatusChange,
  onDelete,
  onPasswordChange,
  passwordChangeForm,
  onPasswordFormChange,
  onPasswordFormCancel
}: ClientListProps) {
  return (
    <div className="space-y-4">
      {clients.map(client => (
        <div key={client.id} className="border rounded p-4">
          <div className="flex justify-between items-center mb-2">
            <div>
              <p className="font-medium">{client.username}</p>
              <p className="text-sm text-gray-500">Limit: {client.maxWishesLimit}</p>
              <p className="text-xs text-gray-400">
                Last login: {client.lastLogin ? new Date(client.lastLogin).toLocaleString() : 'Never'}
              </p>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => onStatusChange(client.id, !client.isActive)}
                className={`p-2 rounded ${
                  client.isActive 
                    ? 'bg-green-100 text-green-600' 
                    : 'bg-red-100 text-red-600'
                }`}
                title={client.isActive ? 'Deactivate' : 'Activate'}
              >
                {client.isActive ? <Unlock className="w-5 h-5" /> : <Lock className="w-5 h-5" />}
              </button>
              <button
                onClick={() => onDelete(client.id)}
                className="p-2 rounded bg-red-100 text-red-600 hover:bg-red-200"
                title="Delete Client"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>
          </div>

          {passwordChangeForm.adminId === client.id ? (
            <div className="mt-2 flex gap-2">
              <input
                type="password"
                placeholder="New Password"
                value={passwordChangeForm.newPassword}
                onChange={(e) => onPasswordFormChange(client.id, e.target.value)}
                className="flex-1 p-2 border rounded"
              />
              <button
                onClick={() => onPasswordChange(client.id)}
                className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
              >
                Update
              </button>
              <button
                onClick={onPasswordFormCancel}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              onClick={() => onPasswordFormChange(client.id, '')}
              className="mt-2 flex items-center gap-1 text-sm text-blue-600 hover:text-blue-700"
            >
              <Key className="w-4 h-4" /> Change Password
            </button>
          )}
        </div>
      ))}
    </div>
  );
}