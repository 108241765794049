import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Lock } from 'lucide-react';
import { superAdminManager } from '../lib/superAdmin';

// For initial setup only - DO NOT use hardcoded credentials in production
const SUPER_ADMIN_USERNAME = 'superadmin';
const SUPER_ADMIN_PASSWORD = 'SuperAdmin2024!';

export default function SuperAdminLogin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const initializeSuperAdmin = async () => {
      try {
        const admins = await superAdminManager.getAllAdmins();
        // If no admins exist, create a default client
        if (admins.length === 0) {
          await superAdminManager.createAdmin(
            'client',           // default username
            'Client2024!',      // default password
            1000,              // maxWishesLimit
            undefined          // customDomain
          );
          console.log('Default client account created');
        }
      } catch (error) {
        console.error('Error initializing:', error);
      }
    };

    initializeSuperAdmin();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (
      username === SUPER_ADMIN_USERNAME && 
      password === SUPER_ADMIN_PASSWORD
    ) {
      sessionStorage.setItem('superAdminAuthenticated', 'true');
      navigate('/super-admin/dashboard');
    } else {
      setError('Invalid super admin credentials');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-600 to-purple-500 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-2xl shadow-xl p-8">
        <div className="text-center mb-8">
          <Lock className="w-12 h-12 mx-auto mb-4 text-indigo-600" />
          <h2 className="text-3xl font-bold text-gray-800">Super Admin Login</h2>
          {error && (
            <div className="mt-4 p-3 bg-red-100 text-red-700 rounded-lg">
              {error}
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Username
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full py-3 px-4 bg-indigo-600 hover:bg-indigo-700 text-white font-medium rounded-lg"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
}