import React from 'react';
import { Settings } from 'lucide-react';
import type { PlatformSettings as PlatformSettingsType } from '../../lib/types/admin';

interface PlatformSettingsProps {
  settings: PlatformSettingsType;
  onSettingsChange: (settings: PlatformSettingsType) => void;
  onSave: () => void;
}

export function PlatformSettings({ settings, onSettingsChange, onSave }: PlatformSettingsProps) {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4 flex items-center">
        <Settings className="mr-2" /> Platform Settings
      </h2>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Global Wishes Limit
          </label>
          <input
            type="number"
            value={settings.globalWishesLimit}
            onChange={(e) => onSettingsChange({
              ...settings,
              globalWishesLimit: Number(e.target.value)
            })}
            className="w-full p-2 border rounded"
            min="1000"
            max="100000"
          />
        </div>

        <div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={settings.maintenanceMode}
              onChange={(e) => onSettingsChange({
                ...settings,
                maintenanceMode: e.target.checked
              })}
              className="rounded border-gray-300"
            />
            <span>Maintenance Mode</span>
          </label>
        </div>

        <div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={settings.allowNewAdminRegistration}
              onChange={(e) => onSettingsChange({
                ...settings,
                allowNewAdminRegistration: e.target.checked
              })}
              className="rounded border-gray-300"
            />
            <span>Allow New Client Registration</span>
          </label>
        </div>

        <button
          onClick={onSave}
          className="w-full bg-purple-600 text-white p-2 rounded hover:bg-purple-700"
        >
          Update Platform Settings
        </button>
      </div>
    </div>
  );
}