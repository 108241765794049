import React, { useState, useEffect } from 'react';
import { getAllWishes, updateWishApproval } from '../../lib/db';
import { Check, X } from 'lucide-react';
import type { Wish } from '../../lib/types/wishes';

export default function WishesManagement() {
  const [wishes, setWishes] = useState<Wish[]>([]);

  useEffect(() => {
    const loadWishes = async () => {
      const loadedWishes = await getAllWishes();
      setWishes(loadedWishes.sort((a, b) => b.timestamp - a.timestamp));
    };

    loadWishes();
    const interval = setInterval(loadWishes, 5000);
    return () => clearInterval(interval);
  }, []);

  const handleApproval = async (id: number, approved: boolean) => {
    await updateWishApproval(id, approved);
    setWishes(wishes.map(wish => 
      wish.id === id ? { ...wish, approved } : wish
    ));
  };

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Messages</h2>
      <div className="space-y-4">
        {wishes.map((wish) => (
          <div
            key={wish.id}
            className="border rounded-lg p-4 bg-gray-50"
          >
            <div className="flex justify-between items-start">
              <div>
                <p className="font-semibold">{wish.name}</p>
                <p className="text-gray-600">{wish.message}</p>
                {wish.imageUrl && (
                  <img
                    src={wish.imageUrl}
                    alt="Wish"
                    className="mt-2 max-h-32 rounded"
                  />
                )}
                <p className="text-sm text-gray-500 mt-2">
                  {new Date(wish.timestamp).toLocaleString()}
                </p>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleApproval(wish.id!, true)}
                  className={`p-2 rounded-full ${
                    wish.approved
                      ? 'bg-green-100 text-green-600'
                      : 'bg-gray-100 text-gray-600 hover:bg-green-100 hover:text-green-600'
                  }`}
                >
                  <Check className="w-5 h-5" />
                </button>
                <button
                  onClick={() => handleApproval(wish.id!, false)}
                  className={`p-2 rounded-full ${
                    !wish.approved
                      ? 'bg-red-100 text-red-600'
                      : 'bg-gray-100 text-gray-600 hover:bg-red-100 hover:text-red-600'
                  }`}
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}