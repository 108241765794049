import { sessionManager } from '../session/sessionManager';
import { superAdminManager } from '../superAdmin';

export async function authenticateClient(username: string, password: string): Promise<boolean> {
  try {
    const admin = await superAdminManager.authenticateAdmin(username, password);
    if (!admin) return false;

    // End any existing session
    await sessionManager.endSession(username);

    // Create new session
    await sessionManager.createSession(username, admin.accessKey);
    
    // Store session info
    sessionStorage.setItem('clientAuthenticated', 'true');
    sessionStorage.setItem('clientUsername', username);
    sessionStorage.setItem('clientAccessKey', admin.accessKey);

    return true;
  } catch (error) {
    console.error('Authentication error:', error);
    return false;
  }
}

export async function validateClientSession(): Promise<boolean> {
  const username = sessionStorage.getItem('clientUsername');
  const accessKey = sessionStorage.getItem('clientAccessKey');

  if (!username || !accessKey) {
    return false;
  }

  return sessionManager.validateSession(username, accessKey);
}

export async function clientLogout(username: string): Promise<void> {
  await sessionManager.endSession(username);
  sessionStorage.removeItem('clientAuthenticated');
  sessionStorage.removeItem('clientUsername');
  sessionStorage.removeItem('clientAccessKey');
}