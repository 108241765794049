import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DisplayScreen from './components/DisplayScreen';
import WishForm from './components/WishForm';
import ClientLogin from './components/ClientLogin';
import ClientDashboard from './components/ClientDashboard';
import SuperAdminLogin from './components/SuperAdminLogin';
import SuperAdminDashboard from './components/super-admin/SuperAdminDashboard';
import SuperAdminProtectedRoute from './components/SuperAdminProtectedRoute';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DisplayScreen />} />
        <Route path="/display/:accessKey" element={<DisplayScreen />} />
        <Route path="/form" element={<WishForm />} />
        <Route path="/form/:accessKey" element={<WishForm />} />
        <Route path="/client" element={<ClientLogin />} />
        <Route path="/client/dashboard" element={<ClientDashboard />} />
        <Route path="/super-admin/login" element={<SuperAdminLogin />} />
        <Route 
          path="/super-admin/dashboard" 
          element={
            <SuperAdminProtectedRoute>
              <SuperAdminDashboard />
            </SuperAdminProtectedRoute>
          } 
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;