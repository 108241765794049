import { openDB } from 'idb';
import { superAdminManager } from './superAdmin';
import { DB_NAMES, STORE_NAMES } from './constants/database';
import type { Wish, AnimationStyle } from './types/wishes';

const db = await openDB(DB_NAMES.WISHES, 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(STORE_NAMES.WISHES)) {
      const store = db.createObjectStore(STORE_NAMES.WISHES, { 
        keyPath: 'id', 
        autoIncrement: true 
      });
      store.createIndex('timestamp', 'timestamp');
      store.createIndex('approved', 'approved');
      store.createIndex('accessKey', 'accessKey');
    }

    if (!db.objectStoreNames.contains(STORE_NAMES.SETTINGS)) {
      const store = db.createObjectStore(STORE_NAMES.SETTINGS);
      store.put('Send Your Wishes Here', 'title');
      store.put('Made with ❤️', 'footerTitle');
      store.put(true, 'requireApproval');
      store.put('from-purple-600 to-blue-500', 'backgroundGradient');
      store.put(8000, 'animationDuration');
      store.put(1, 'displayCount');
      store.put('bubble', 'animationStyle');
      store.put('', 'backgroundImage');
      store.put('', 'backgroundVideo');
      store.put(true, 'videoLoop');
      store.put(false, 'randomDisplay');
      store.put('text-white', 'titleColor');
      store.put(true, 'titleShadow');
      store.put('border-white', 'photoFrameColor');
      store.put('shadow-xl', 'photoShadow');
    }
  },
});

export async function addWish(wish: Omit<Wish, 'id' | 'timestamp' | 'approved'>): Promise<Wish> {
  const requireApproval = await getSetting('requireApproval');
  
  const timestamp = Date.now();
  const approved = !requireApproval;
  const id = await db.add(STORE_NAMES.WISHES, { ...wish, timestamp, approved });
  return { id, ...wish, timestamp, approved };
}

export async function getAllWishes(): Promise<Wish[]> {
  return db.getAllFromIndex(STORE_NAMES.WISHES, 'timestamp');
}

export async function getApprovedWishes(accessKey?: string): Promise<Wish[]> {
  const tx = db.transaction(STORE_NAMES.WISHES, 'readonly');
  const store = tx.store;
  const wishes = await store.getAll();
  
  return wishes.filter(wish => {
    const matchesAccess = !accessKey || wish.accessKey === accessKey;
    return wish.approved && matchesAccess;
  });
}

export async function updateWishApproval(id: number, approved: boolean): Promise<void> {
  const tx = db.transaction(STORE_NAMES.WISHES, 'readwrite');
  const wish = await tx.store.get(id);
  if (wish) {
    wish.approved = approved;
    await tx.store.put(wish);
  }
  await tx.done;
}

export async function storeImage(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export async function verifyAdmin(username: string, password: string): Promise<boolean> {
  const admin = await superAdminManager.authenticateAdmin(username, password);
  return admin !== null;
}

export async function getSetting(key: string): Promise<any> {
  return db.get(STORE_NAMES.SETTINGS, key);
}

export async function updateSetting(key: string, value: any): Promise<void> {
  await db.put(STORE_NAMES.SETTINGS, value, key);
}

export async function exportWishesData(): Promise<string> {
  const wishes = await getAllWishes();
  const data = {
    wishes,
    exportDate: new Date().toISOString()
  };
  return JSON.stringify(data, null, 2);
}

export async function downloadAllPhotos(): Promise<{ [key: string]: string }> {
  const wishes = await getAllWishes();
  const photos: { [key: string]: string } = {};
  
  wishes.forEach(wish => {
    if (wish.imageUrl) {
      const fileName = `photo_${wish.id}_${new Date(wish.timestamp).toISOString().split('T')[0]}.jpg`;
      photos[fileName] = wish.imageUrl;
    }
  });
  
  return photos;
}