import React, { useState, useEffect } from 'react';
import { getSetting, updateSetting, storeImage } from '../../lib/db';
import { Upload } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import type { AnimationStyle } from '../../lib/types/wishes';

export default function SettingsPanel() {
  const [title, setTitle] = useState('');
  const [footerTitle, setFooterTitle] = useState('');
  const [requireApproval, setRequireApproval] = useState(true);
  const [backgroundGradient, setBackgroundGradient] = useState('from-purple-600 to-blue-500');
  const [animationDuration, setAnimationDuration] = useState(8000);
  const [displayCount, setDisplayCount] = useState(1);
  const [animationStyle, setAnimationStyle] = useState<AnimationStyle>('bubble');
  const [backgroundMedia, setBackgroundMedia] = useState('');
  const [videoLoop, setVideoLoop] = useState(true);
  const [randomDisplay, setRandomDisplay] = useState(false);
  const [titleColor, setTitleColor] = useState('text-white');
  const [titleShadow, setTitleShadow] = useState(true);
  const [photoFrameColor, setPhotoFrameColor] = useState('border-white');
  const [photoShadow, setPhotoShadow] = useState('shadow-xl');
  const [sessionTimeout, setSessionTimeout] = useState(30);
  const [message, setMessage] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
      'video/mp4': []
    },
    maxFiles: 1,
    onDrop: async (files) => {
      if (files[0]) {
        const mediaUrl = await storeImage(files[0]);
        setBackgroundMedia(mediaUrl);
        const isVideo = files[0].type.startsWith('video');
        await updateSetting(isVideo ? 'backgroundVideo' : 'backgroundImage', mediaUrl);
        await updateSetting(isVideo ? 'backgroundImage' : 'backgroundVideo', '');
        showMessage(`Background ${isVideo ? 'video' : 'image'} updated successfully`);
      }
    }
  });

  useEffect(() => {
    const loadSettings = async () => {
      const [
        currentTitle,
        currentFooterTitle,
        approval,
        gradient,
        duration,
        count,
        style,
        bgImage,
        bgVideo,
        loop,
        random,
        tColor,
        tShadow,
        pFrameColor,
        pShadow,
        timeout
      ] = await Promise.all([
        getSetting('title'),
        getSetting('footerTitle'),
        getSetting('requireApproval'),
        getSetting('backgroundGradient'),
        getSetting('animationDuration'),
        getSetting('displayCount'),
        getSetting('animationStyle'),
        getSetting('backgroundImage'),
        getSetting('backgroundVideo'),
        getSetting('videoLoop'),
        getSetting('randomDisplay'),
        getSetting('titleColor'),
        getSetting('titleShadow'),
        getSetting('photoFrameColor'),
        getSetting('photoShadow'),
        getSetting('sessionTimeout')
      ]);
      
      setTitle(currentTitle);
      setFooterTitle(currentFooterTitle);
      setRequireApproval(approval);
      setBackgroundGradient(gradient);
      setAnimationDuration(duration);
      setDisplayCount(count);
      setAnimationStyle(style);
      setBackgroundMedia(bgImage || bgVideo);
      setVideoLoop(loop);
      setRandomDisplay(random);
      setTitleColor(tColor);
      setTitleShadow(tShadow);
      setPhotoFrameColor(pFrameColor);
      setPhotoShadow(pShadow);
      setSessionTimeout(timeout || 30);
    };

    loadSettings();
  }, []);

  const showMessage = (text: string) => {
    setMessage(text);
    setTimeout(() => setMessage(''), 3000);
  };

  const handleUpdate = async (key: string, value: any, successMessage: string) => {
    await updateSetting(key, value);
    showMessage(successMessage);
  };

  const clearBackgroundMedia = async () => {
    await updateSetting('backgroundImage', '');
    await updateSetting('backgroundVideo', '');
    setBackgroundMedia('');
    showMessage('Background media cleared successfully');
  };

  const handleCheckboxChange = async (key: string, checked: boolean, message: string) => {
    try {
      await updateSetting(key, checked);
      switch (key) {
        case 'requireApproval':
          setRequireApproval(checked);
          break;
        case 'randomDisplay':
          setRandomDisplay(checked);
          break;
        case 'titleShadow':
          setTitleShadow(checked);
          break;
        case 'videoLoop':
          setVideoLoop(checked);
          break;
      }
      showMessage(message);
    } catch (error) {
      showMessage('Failed to update setting');
    }
  };

  return (
    <div className="space-y-4">
      {message && (
        <div className="p-3 bg-green-100 text-green-700 rounded-lg">
          {message}
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Display Title
        </label>
        <div className="flex gap-2">
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="flex-1 px-4 py-2 border border-gray-300 rounded-lg"
          />
          <button
            onClick={() => handleUpdate('title', title, 'Title updated successfully')}
            className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            Update
          </button>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Footer Title
        </label>
        <div className="flex gap-2">
          <input
            type="text"
            value={footerTitle}
            onChange={(e) => setFooterTitle(e.target.value)}
            className="flex-1 px-4 py-2 border border-gray-300 rounded-lg"
          />
          <button
            onClick={() => handleUpdate('footerTitle', footerTitle, 'Footer updated successfully')}
            className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            Update
          </button>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Background
        </label>
        <div className="space-y-2">
          <div className="flex gap-2">
            <input
              type="text"
              value={backgroundGradient}
              onChange={(e) => setBackgroundGradient(e.target.value)}
              className="flex-1 px-4 py-2 border border-gray-300 rounded-lg"
              placeholder="e.g., from-blue-500 to-purple-500"
            />
            <button
              onClick={() => handleUpdate('backgroundGradient', backgroundGradient, 'Background updated successfully')}
              className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
            >
              Update
            </button>
          </div>
          
          <div {...getRootProps()} className="border-2 border-dashed border-gray-300 rounded-lg p-4 text-center cursor-pointer hover:border-purple-500">
            <input {...getInputProps()} />
            <Upload className="w-8 h-8 mx-auto mb-2 text-gray-400" />
            <p className="text-sm text-gray-500">Drop an image/video or click to select</p>
            <p className="text-xs text-gray-400 mt-1">Supports images and MP4 videos</p>
          </div>

          {backgroundMedia && (
            <>
              <button
                onClick={clearBackgroundMedia}
                className="w-full px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 mb-2"
              >
                Clear Background Media
              </button>
              {backgroundMedia.includes('video') && (
                <button
                  onClick={() => handleCheckboxChange('videoLoop', !videoLoop, 'Video loop updated successfully')}
                  className={`w-full px-4 py-2 ${
                    videoLoop 
                      ? 'bg-green-600 hover:bg-green-700' 
                      : 'bg-yellow-600 hover:bg-yellow-700'
                  } text-white rounded-lg`}
                >
                  Video Loop: {videoLoop ? 'On' : 'Off'}
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Messages to Display
        </label>
        <div className="flex gap-2">
          <input
            type="number"
            value={displayCount}
            onChange={(e) => setDisplayCount(Number(e.target.value))}
            className="flex-1 px-4 py-2 border border-gray-300 rounded-lg"
            min="1"
            max="4"
          />
          <button
            onClick={() => handleUpdate('displayCount', displayCount, 'Display count updated successfully')}
            className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            Update
          </button>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Animation Style
        </label>
        <div className="flex gap-2">
          <select
            value={animationStyle}
            onChange={(e) => setAnimationStyle(e.target.value as AnimationStyle)}
            className="flex-1 px-4 py-2 border border-gray-300 rounded-lg"
          >
            <option value="bubble">Bubble Animation</option>
            <option value="fade">Fade Animation</option>
            <option value="slide">Slide Animation</option>
            <option value="carousel">Carousel Animation</option>
            <option value="zoom">Zoom Animation</option>
          </select>
          <button
            onClick={() => handleUpdate('animationStyle', animationStyle, 'Animation style updated successfully')}
            className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            Update
          </button>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Animation Duration (ms)
        </label>
        <div className="flex gap-2">
          <input
            type="number"
            value={animationDuration}
            onChange={(e) => setAnimationDuration(Number(e.target.value))}
            className="flex-1 px-4 py-2 border border-gray-300 rounded-lg"
            min="1000"
            step="1000"
          />
          <button
            onClick={() => handleUpdate('animationDuration', animationDuration, 'Animation duration updated successfully')}
            className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            Update
          </button>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Session Timeout (minutes)
        </label>
        <div className="flex gap-2">
          <input
            type="number"
            value={sessionTimeout}
            onChange={(e) => setSessionTimeout(Number(e.target.value))}
            className="flex-1 px-4 py-2 border border-gray-300 rounded-lg"
            min="5"
            max="120"
            step="5"
          />
          <button
            onClick={() => handleUpdate('sessionTimeout', sessionTimeout, 'Session timeout updated successfully')}
            className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            Update
          </button>
        </div>
      </div>

      <div className="space-y-4">
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={requireApproval}
            onChange={(e) => handleCheckboxChange('requireApproval', e.target.checked, 'Approval setting updated')}
            className="w-4 h-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500"
          />
          <span>Require message approval</span>
        </label>

        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={randomDisplay}
            onChange={(e) => handleCheckboxChange('randomDisplay', e.target.checked, 'Random display updated')}
            className="w-4 h-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500"
          />
          <span>Enable random message display</span>
        </label>

        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={titleShadow}
            onChange={(e) => handleCheckboxChange('titleShadow', e.target.checked, 'Title shadow updated')}
            className="w-4 h-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500"
          />
          <span>Enable title shadow</span>
        </label>
      </div>
    </div>
  );
}