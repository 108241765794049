import React, { useState } from 'react';
import { validateUsername, validatePassword } from '../../lib/validation/adminValidation';
import type { AdminConfig } from '../../lib/types/admin';

interface CreateClientFormProps {
  onClientCreated: (newClient: AdminConfig) => void;
}

export function CreateClientForm({ onClientCreated }: CreateClientFormProps) {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    maxWishesLimit: 1000
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    const usernameError = validateUsername(formData.username);
    if (usernameError) {
      setError(usernameError);
      return;
    }

    const passwordError = validatePassword(formData.password);
    if (passwordError) {
      setError(passwordError);
      return;
    }

    try {
      const response = await fetch('/api/clients', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();
      
      if (!data.success) {
        throw new Error(data.message);
      }

      const newClient: AdminConfig = {
        ...data.data,
        id: data.data.accessKey,
        isActive: true,
        createdAt: Date.now(),
        hashedPassword: '',
        maxWishesLimit: formData.maxWishesLimit
      };

      onClientCreated(newClient);
      
      setFormData({
        username: '',
        password: '',
        maxWishesLimit: 1000
      });
      
      setSuccess('Client created successfully!');
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to create client');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 mb-6">
      {error && (
        <div className="p-3 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}
      {success && (
        <div className="p-3 bg-green-100 text-green-700 rounded-lg">
          {success}
        </div>
      )}
      
      <input
        type="text"
        placeholder="Username"
        value={formData.username}
        onChange={(e) => setFormData({...formData, username: e.target.value})}
        className="w-full p-2 border rounded"
        required
      />
      <input
        type="password"
        placeholder="Password"
        value={formData.password}
        onChange={(e) => setFormData({...formData, password: e.target.value})}
        className="w-full p-2 border rounded"
        required
      />
      <input
        type="number"
        placeholder="Max Wishes Limit"
        value={formData.maxWishesLimit}
        onChange={(e) => setFormData({...formData, maxWishesLimit: Number(e.target.value)})}
        className="w-full p-2 border rounded"
        min="1"
        max="10000"
      />
      <button 
        type="submit" 
        className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
      >
        Add Client
      </button>
    </form>
  );
}