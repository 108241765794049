import React, { useState, useEffect } from 'react';
import { CreateClientForm } from './CreateClientForm';
import { ClientList } from './ClientList';
import { PlatformSettings } from './PlatformSettings';
import { superAdminManager } from '../../lib/superAdmin';
import { validatePassword } from '../../lib/validation/adminValidation';
import type { AdminConfig, PlatformSettings as PlatformSettingsType } from '../../lib/types/admin';

const SuperAdminDashboard: React.FC = () => {
  const [admins, setAdmins] = useState<AdminConfig[]>([]);
  const [platformSettings, setPlatformSettings] = useState<PlatformSettingsType>({
    globalWishesLimit: 10000,
    maintenanceMode: false,
    allowNewAdminRegistration: true
  });
  const [passwordChangeForm, setPasswordChangeForm] = useState({
    adminId: '',
    newPassword: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const loadData = async () => {
      try {
        const [loadedAdmins, settings] = await Promise.all([
          superAdminManager.getAllAdmins(),
          superAdminManager.getPlatformSettings()
        ]);
        setAdmins(loadedAdmins);
        setPlatformSettings(settings);
      } catch (error) {
        setError('Failed to load dashboard data');
      }
    };

    loadData();
  }, []);

  const handleClientCreated = (newClient: AdminConfig) => {
    setAdmins([...admins, newClient]);
  };

  const handleStatusChange = async (adminId: string, isActive: boolean) => {
    try {
      await superAdminManager.updateAdminStatus(adminId, { isActive });
      setAdmins(admins.map(admin => 
        admin.id === adminId ? { ...admin, isActive } : admin
      ));
      setSuccess(`Client ${isActive ? 'activated' : 'deactivated'} successfully!`);
    } catch (error) {
      setError('Failed to update client status');
    }
  };

  const handleDelete = async (adminId: string) => {
    if (!window.confirm('Are you sure you want to delete this client?')) {
      return;
    }

    try {
      await superAdminManager.deleteAdmin(adminId);
      setAdmins(admins.filter(admin => admin.id !== adminId));
      setSuccess('Client deleted successfully!');
    } catch (error) {
      setError('Failed to delete client');
    }
  };

  const handlePasswordChange = async (adminId: string) => {
    const admin = admins.find(a => a.id === adminId);
    if (!admin) {
      setError('Client not found');
      return;
    }

    const passwordError = validatePassword(passwordChangeForm.newPassword);
    if (passwordError) {
      setError(passwordError);
      return;
    }

    try {
      await superAdminManager.updateAdminPassword(admin.username, passwordChangeForm.newPassword);
      setSuccess('Password updated successfully!');
      setPasswordChangeForm({ adminId: '', newPassword: '' });
    } catch (error) {
      setError('Failed to update password');
    }
  };

  const handlePlatformSettingsUpdate = async () => {
    try {
      await superAdminManager.updatePlatformSettings(platformSettings);
      setSuccess('Platform settings updated successfully!');
    } catch (error) {
      setError('Failed to update platform settings');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h1 className="text-3xl font-bold mb-6 text-center">Super Admin Dashboard</h1>

          {error && (
            <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
              {error}
            </div>
          )}

          {success && (
            <div className="mb-4 p-3 bg-green-100 text-green-700 rounded-lg">
              {success}
            </div>
          )}

          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <h2 className="text-xl font-semibold mb-4">Client Management</h2>
              <CreateClientForm onClientCreated={handleClientCreated} />
              <ClientList
                clients={admins}
                onStatusChange={handleStatusChange}
                onDelete={handleDelete}
                onPasswordChange={handlePasswordChange}
                passwordChangeForm={passwordChangeForm}
                onPasswordFormChange={(adminId, newPassword) => 
                  setPasswordChangeForm({ adminId, newPassword })}
                onPasswordFormCancel={() => 
                  setPasswordChangeForm({ adminId: '', newPassword: '' })}
              />
            </div>

            <div>
              <PlatformSettings
                settings={platformSettings}
                onSettingsChange={setPlatformSettings}
                onSave={handlePlatformSettingsUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminDashboard;